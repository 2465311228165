(function () {
  'use strict';

  /* ------------------------------------- */

  /* animations mixin */

  /* ------------------------------------- */
  var animationsMixin = {
    mounted() {
      /* preloader screen */
      this.animPreloaderScreen(); // initialize animation effects

      window.addEventListener('load', () => this.initAnimation());
    },

    methods: {
      // preloader screen
      animPreloaderScreen() {
        let count = 0;
        const preloader = this.$refs.preloader;

        if (!preloader) {
          return;
        }

        const preloaderContent = preloader.querySelector(".preloader-content");
        const imgs = [...document.images];
        const imgsLength = imgs.length;

        const hidePreloader = () => {
          preloader.setAttribute("style", "--loading-percentage: 100%");
          gsap.timeline().set(".hide-in-preloading", {
            autoAlpha: 1
          }).to(preloaderContent, {
            delay: 0.5,
            autoAlpha: 0
          }).to(preloader, {
            y: "-100%",
            duration: 1,
            ease: "expo.in"
          }, "-=0.5").set(preloader, {
            autoAlpha: 0
          });
        };

        const imgLoaded = () => {
          count++;
          this.loadingPercentage = 100 / imgsLength * count << 0;
          preloader.setAttribute("style", `--loading-percentage: ${this.loadingPercentage}%`);

          if (count === imgsLength) {
            hidePreloader();
          }
        };

        if (imgsLength) {
          // setup preloader indicator
          imgs.forEach(img => {
            const tImg = new Image();
            tImg.onload = imgLoaded;
            tImg.onerror = imgLoaded;
            tImg.src = img.src;
          });
        } else {
          hidePreloader();
        }
      },

      // initialize animation effects
      initAnimation() {
        gsap.registerPlugin(ScrollTrigger);

        /* back to top scroll indicator */
        this.animBackTopScrollIndicator();

        /* statistics items */
        this.animStatisticsItems();

        /* section text box */
        this.animSectionTextBox();

        /* about image */
        this.animAboutImage();

        /* skills items */
        this.animSkillsItems();

        /* experience items timeline */
        this.animExperienceItemsTimeline();

        /* contact info */
        this.animContactInfo();

        /* contact form */
        this.animContactForm();
      },

      // back to top scroll indicator
      animBackTopScrollIndicator() {
        const backTopBtn = this.$refs.scrollTopBtn;

        if (!backTopBtn) {
          return;
        }

        const showAt = backTopBtn.getAttribute('data-show-at');
        const backTopBtnPath = backTopBtn.querySelector("path");
        const backTopBtnPathLength = backTopBtnPath.getTotalLength();
        gsap.from(backTopBtn, {
          ease: "none",
          duration: 0.3,
          autoAlpha: 0,
          y: 10,
          scrollTrigger: {
            trigger: "#app-inner",
            start: `${showAt}px top`,
            end: "bottom bottom",
            toggleActions: "play none none reverse"
          }
        });
        gsap.set(backTopBtnPath, {
          strokeDasharray: backTopBtnPathLength,
          strokeDashoffset: backTopBtnPathLength,
          scrollTrigger: {
            trigger: "#app-inner",
            start: `${showAt}px top`,
            end: "bottom bottom",
            onUpdate: self => backTopBtnPath.style.strokeDashoffset = backTopBtnPathLength - self.progress * backTopBtnPathLength
          }
        });
      },

      // statistics items
      animStatisticsItems() {
        const statisticsItems = gsap.utils.toArray(".statistics-section .statistics-items li");

        if (!statisticsItems.length) {
          return;
        }

        const statisticsItemsTL = gsap.timeline({
          scrollTrigger: {
            trigger: ".statistics-items",
            start: "top 82%",
            end: "top 50%",
            scrub: 0.3
          }
        });
        statisticsItems.forEach((el, i) => {
          const pos = i === 0 ? "" : "< +=0.2";
          statisticsItemsTL.from(el, {
            autoAlpha: 0
          }, pos).from(el, {
            y: 50
          }, "<");
        });
      },

      // section text box
      animSectionTextBox() {
        const textBoxes = gsap.utils.toArray(".text-box-inline");

        if (!textBoxes.length) {
          return;
        }

        textBoxes.forEach(box => {
          gsap.timeline({
            scrollTrigger: {
              trigger: box,
              start: "top 85%",
              end: "top 35%",
              scrub: 0.3
            }
          }).from(box.querySelector(".subtitle"), {
            autoAlpha: 0,
            top: 50
          }).from(box.querySelector("h2"), {
            autoAlpha: 0,
            y: 50
          }, "-=0.2").from(box.querySelectorAll("h2 ~ *"), {
            autoAlpha: 0,
            y: 50,
            stagger: 0.2
          }, "-=0.2");
        });
      },

      // about image
      animAboutImage() {
        if (!this.$refs.aboutSection) {
          return;
        }

        gsap.timeline({
          scrollTrigger: {
            trigger: ".about-section .about-img",
            start: "top 80%",
            end: "top 50%",
            scrub: 0.3
          }
        }).from(".about-section .about-img", {
          autoAlpha: 0,
          scale: 0.5
        });
      },

      // skills items
      animSkillsItems() {
        const skillsGroups = gsap.utils.toArray(".skills-section .skills-items ul");

        if (!skillsGroups.length) {
          return;
        }

        skillsGroups.forEach(group => {
          const skillsItemsTL = gsap.timeline({
            scrollTrigger: {
              trigger: ".skills-section .skills-items",
              start: "top 85%",
              end: "top 35%",
              scrub: 0.3
            }
          });
          group.querySelectorAll("li").forEach((el, i) => {
            const pos = i === 0 ? "" : "< +=0.2";
            skillsItemsTL.from(el, {
              autoAlpha: 0
            }, pos).from(el, {
              y: 50
            }, "<");
          });
        });
      },

      // experience items timeline
      animExperienceItemsTimeline() {
        const experienceTimepath = this.$refs.experienceTimepath;
        const experienceItems = gsap.utils.toArray(".experience-timeline .timeline-items li");
        let experienceTimepathTL;
        let experienceItemsTL;
        let mainExperienceTL;

        if (experienceTimepath || experienceItems.length) {
          mainExperienceTL = gsap.timeline({
            scrollTrigger: {
              trigger: ".experience-section .experience-timeline",
              start: "top 85%",
              end: "top 35%",
              scrub: 0.3
            }
          });
        }

        if (experienceTimepath) {
          const experienceTimepathItems = gsap.utils.toArray(".experience-timeline .timepath span");
          experienceTimepathTL = gsap.timeline();
          const docDir = document.documentElement.dir;
          const fromDir = docDir === "rtl" ? "reverse" : "from";
          const reverseDir = docDir === "rtl" ? "from" : "reverse";
          const coords = {
            x: {
              from: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)",
              reverse: "polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)",
              to: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)"
            },
            c: {
              from: "polygon(0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 75%, 0% 75%, 0% 75%, 0% 75%)",
              reverse: "polygon(100% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 75%, 100% 75%, 100% 75%, 100% 75%)",
              to: {
                from: {
                  st1: "polygon(0% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%, 75% 25%, 75% 25%, 0% 25%)",
                  st2: "polygon(0% 0%, 100% 0%, 100% 100%, 100% 100%, 75% 75%, 75% 75%, 75% 25%, 0% 25%)",
                  st3: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 75%, 75% 75%, 75% 25%, 0% 25%)"
                },
                reverse: {
                  st1: "polygon(100% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 25% 25%, 25% 25%, 100% 25%)",
                  st2: "polygon(100% 0%, 0% 0%, 0% 100%, 0% 100%, 25% 75%, 25% 75%, 25% 25%, 100% 25%)",
                  st3: "polygon(100% 0%, 0% 0%, 0% 100%, 100% 100%, 100% 75%, 25% 75%, 25% 25%, 100% 25%)"
                }
              }
            }
          };
          const lineOdd = [...experienceTimepath.querySelectorAll(".line:nth-of-type(4n + 1)")];
          const lineEven = [...experienceTimepath.querySelectorAll(".line:nth-of-type(4n + 3)")];
          const semicircleOdd = [...experienceTimepath.querySelectorAll(".semicircle:nth-of-type(4n + 2)")];
          const semicircleEven = [...experienceTimepath.querySelectorAll(".semicircle:nth-of-type(4n + 4)")];
          experienceTimepathTL.set(experienceTimepathItems, {
            autoAlpha: 1
          }).set(lineOdd, {
            clipPath: coords.x[fromDir]
          }).set(lineEven, {
            clipPath: coords.x[reverseDir]
          }).set(semicircleOdd, {
            clipPath: coords.c[fromDir]
          }).set(semicircleEven, {
            clipPath: coords.c[reverseDir]
          });
          experienceTimepathItems.forEach(el => {
            if (lineOdd.includes(el) || lineEven.includes(el)) {
              experienceTimepathTL.to(el, {
                clipPath: coords.x.to
              });
            } else if (semicircleOdd.includes(el)) {
              experienceTimepathTL.to(el, {
                clipPath: coords.c.to[fromDir].st1
              }).to(el, {
                clipPath: coords.c.to[fromDir].st2
              }).to(el, {
                clipPath: coords.c.to[fromDir].st3
              });
            } else if (semicircleEven.includes(el)) {
              experienceTimepathTL.to(el, {
                clipPath: coords.c.to[reverseDir].st1
              }).to(el, {
                clipPath: coords.c.to[reverseDir].st2
              }).to(el, {
                clipPath: coords.c.to[reverseDir].st3
              });
            }
          });
          mainExperienceTL.add(experienceTimepathTL);
        }

        if (experienceItems.length) {
          experienceItemsTL = gsap.timeline();
          experienceItems.forEach(el => {
            experienceItemsTL.from(el, {
              autoAlpha: 0
            }).from(el, {
              scale: 0.2
            }, "<");
          });
          mainExperienceTL.add(experienceItemsTL, "< +=0.5");
        }
      },

      // contact info
      animContactInfo() {
        const contactInfoItems = gsap.utils.toArray(".contact-section .contact-info li");

        if (!contactInfoItems.length) {
          return;
        }

        const contactInfoTL = gsap.timeline({
          scrollTrigger: {
            trigger: ".contact-section .contact-info",
            start: "top 80%",
            end: "top 50%",
            scrub: 0.3
          }
        });
        contactInfoItems.forEach((el, i) => {
          const pos = i === 0 ? "" : "< +=0.2";
          contactInfoTL.from(el, {
            autoAlpha: 0
          }, pos).from(el, {
            y: 50
          }, "<");
        }); // social icons animation

        contactInfoTL.from(".contact-section .contact-text .social li", {
          autoAlpha: 0
        }).from(".contact-section .contact-text .social li", {
          y: 50,
          stagger: 0.2
        }, "<");
      },

      // contact form
      animContactForm() {
        if (!this.$refs.contactForm) {
          return;
        }

        gsap.timeline({
          scrollTrigger: {
            trigger: ".contact-section .contact-form",
            start: "top 80%",
            end: "top 50%",
            scrub: 0.3
          }
        }).from(".contact-section .contact-form", {
          autoAlpha: 0,
          scale: 0.7
        });
      }

    }
  };

  /* ------------------------------------- */
  const app = Vue.createApp({
    mixins: [animationsMixin],

    data() {
      return {
        // the date my career started (change to yours)
        careerStartDate: 2020,
        // the date copyright started (change to yours)
        copyrightStartDate: new Date().getFullYear(),
        // for the template theme
        appTheme: 'light_theme',
        savedTheme: null,
        // flag to toggle the preloader
        isPreloading: true,
        // toast notifications array
        notifications: [],
        // manage loading spinner status
        ajaxLoading: [],
        // for minimizing the header on scrolling down
        startMinimizingHeaderAt: 100,
        isHeaderBig: true,
        // for toggling the header on scrolling down
        lastScrollPosition: 0,
        isHeaderHidden: false,
        // flag to toggle focus style class
        isAnyFocus: false,
        // flag to toggle nav menu
        isNavMenuOpen: false,

        // list of nav links to loop through it
        navLinks:
          [
            {
              url: '#hero',
              title: {
                en: 'Home',
              }
            }, {
              url: '#about',
              title: {
                en: 'About',
              }
            }, {
              url: '#skills',
              title: {
                en: 'Skills',
              }
            }, {
              url: '#portfolio',
              title: {
                en: 'Portfolio',
              }
            }, {
              url: '#ctf-writeups',
              title: {
                en: 'CTF Writeups',
              }
            }, {
              url: '#guides',
              title: {
                en: 'Guides',
              }
            }, {
              url: '#contact',
              title: {
                en: 'Contact',
              },
            }],

        // flag to toggle between skills types in skills section
        skillsType: '',

        // list of skills items to loop through it
        skillsItems:
          [
            {
              imgUrl: 'src/assets/tech/html5.png',
              title: 'HTML5'
            }, {
              imgUrl: 'src/assets/tech/css3.png',
              title: 'CSS3'
            }, {
              imgUrl: 'src/assets/tech/javascript.png',
              title: 'JavaScript & TypeScript'
            }, {
              imgUrl: 'src/assets/tech/vue.png',
              title: 'Vue'
            }, {
              imgUrl: 'src/assets/tech/react.png',
              title: 'React'
            }, {
              imgUrl: 'src/assets/tech/svelte.png',
              title: 'Svelte'
            }, {
              imgUrl: 'src/assets/tech/laravel.png',
              title: 'Laravel'
            }, {
              imgUrl: 'src/assets/tech/postgres.png',
              title: 'PostgreSQL'
            }, {
              imgUrl: 'src/assets/tech/python.png',
              title: 'Python'
            }, {
              imgUrl: 'src/assets/tech/mongodb.png',
              title: 'MongoDB'
            }, {
              imgUrl: 'src/assets/tech/java.png',
              title: 'Java'
            }, {
              imgUrl: 'src/assets/tech/mysql.png',
              title: 'MySQL'
            }],

        // list of tools items to loop through it
        toolsItems:
          [
            {
              imgUrl: 'src/assets/tech/jetbrains.png',
              title: 'JetBrains Suite'
            }, {
              imgUrl: 'src/assets/tech/vs-code.png',
              title: 'VS Code'
            }, {
              imgUrl: 'src/assets/tech/kali.png',
              title: 'Kali Linux'
            }, {
              imgUrl: 'src/assets/tech/blender.png',
              title: 'Blender'
            }, {
              imgUrl: 'src/assets/tech/git.png',
              title: 'Git (Github)'
            }, {
              imgUrl: 'src/assets/tech/npm.png',
              title: 'Npm'
            }, {
              imgUrl: 'src/assets/tech/firebase.png',
              title: 'Firebase'
            }, {
              imgUrl: 'src/assets/tech/docker.png',
              title: 'Docker'
            }, {
              imgUrl: 'src/assets/tech/tailwind.png',
              title: 'Tailwind & DaisyUI'
            }, {
              imgUrl: 'src/assets/tech/unity.png',
              title: 'Unity'
            }, {
              imgUrl: 'src/assets/tech/photoshop.png',
              title: 'Photoshop'
            }, {
              imgUrl: 'src/assets/tech/premiere-pro.png',
              title: 'Premiere Pro'
            }],

        // list of experience items to loop through it
        experienceItems:
          [
            {
              date: '2000',
              companyName: {
                en: 'Born!',
              },
              jobTitle: {
                en: 'December 16th',
              },
              desc: {
                en: 'I was born in the danish metropolitan, Odense',
              }
            },

            {
              date: '2007',
              companyName: {
                en: 'Ejerslykkeskolen',
              },
              jobTitle: {
                en: 'Primary Education',
              },
              desc: {
                en: 'I started in the primary school at an age of 7.',
              }
            },

            {
              date: '2016',
              companyName: {
                en: 'Ejerslykkeskolen',
              },
              jobTitle: {
                en: 'Web development and media design',
              },
              desc: {
                en: 'Converted Photoshop layouts to web pages using HTML and CSS',
              }
            },

            {
              date: '2017',
              companyName: {
                en: 'Mulernes Legatskole',
              },
              jobTitle: {
                en: 'Secondary Education',
              },
              desc: {
                en: 'Study programme in Mathematics, Physics and Biotechnology.',
              }
            },

            {
              date: '2020',
              companyName: {
                en: 'University of Southern Denmark',
              },
              jobTitle: {
                en: 'Higher Education',
              },
              desc: {
                en: 'Graduation from ML, and started on my Bachelor in Software Engineering',
              }
            },

            {
              date: '2023',
              companyName: {
                en: 'University of Southern Denmark',
              },
              jobTitle: {
                en: 'BSc in Software Engineering',
              },
              desc: {
                en: 'Got my degree in Software Engineering',
              }
            },

            {
              date: '2024',
              companyName: {
                en: 'Aalborg University in Copenhagen',
              },
              jobTitle: {
                en: 'Cand.polyt in Cybersecurity',
              },
              desc: {
                en: 'Started on cand.polyt in Cybersecurity',
              }
            }, 
            {
              date: '2026',
              companyName: {
                en: 'Aalborg University in Copenhagen',
              },
              jobTitle: {
                en: 'Cand.polyt in Cybersecurity',
              },
              desc: {
                en: 'Expected graduation from cand.polyt in Cybersecurity',
              }
            }, {}],

        // current page of portfolio items
        portfolioItemsPage: 1,

        // current page of ctf items
        ctfItemsPage: 1,

        // portfolio items per page
        itemsPerPage: 7,

        // portfolio items filter by type
        filters: ['All', 'Java', 'Python', 'JavaScript'],
        CTFilters: ['All', 'TryHackMe', 'HackTheBox'],
        currentFilter: 'All',
        currentCTFilter: 'All',

        // archive names
        portfolioArchiveName: '',
        ctfArchiveName: '',

        // CTFs
        allCTFPages:
          [
            {
              id: 1,
              name: '0x41haz',
              description: 'In this challenge, you are asked to solve a simple reversing solution. Download and analyze the binary to discover the password. There may be anti-reversing measures in place!',
              url: 'ctf.html?id=1',
              imgUrl: 'src/assets/CTF/0x41haz/r2.png',
              category: 'TryHackMe',
              location: 'https://github.com/Berkanktk/CTFWriteups/tree/master/TryHackMe/0x41haz'
            }, {
              id: 2,
              name: 'Bounty-Hacker',
              description: 'You were boasting on and on about your elite hacker skills in the bar and a few Bounty Hunters decided they\'d take you up on claims! Prove your status is more than just a few glasses at the bar. I sense bell peppers & beef in your future!',
              url: 'ctf.html?id=2',
              imgUrl: 'src/assets/CTF/Bounty-hacker/THM.jpeg',
              category: 'TryHackMe',
              location: 'https://github.com/Berkanktk/CTFWriteups/tree/master/TryHackMe/Bounty-Hacker'
            }, {
              id: 3,
              name: 'CTF collection Vol.1',
              description: 'Sharpening up your CTF skill with the collection. The first volume is designed for beginner.',
              url: 'ctf.html?id=3',
              imgUrl: 'src/assets/CTF/CTF collection Vol.1/THM.png',
              category: 'TryHackMe',
              location: 'https://github.com/Berkanktk/CTFWriteups/tree/master/TryHackMe/CTF%20collection%20Vol.1'
            }, {
              id: 4,
              name: 'Inclusion',
              description: 'A beginner level LFI challenge',
              url: 'ctf.html?id=4',
              imgUrl: 'src/assets/CTF/Inclusion/THM.png',
              category: 'TryHackMe',
              location: 'https://github.com/Berkanktk/CTFWriteups/tree/master/TryHackMe/Inclusion'
            }, {
              id: 5,
              name: 'Ninja-Skills',
              description: 'Practise your Linux skills and complete the challenges. Answer the questions about the following files...',
              url: 'ctf.html?id=5',
              imgUrl: 'src/assets/CTF/Ninja-Skills/THM.png',
              category: 'TryHackMe',
              location: 'https://github.com/Berkanktk/CTFWriteups/tree/master/TryHackMe/Ninja-Skills'
            }, {
              id: 6,
              name: 'Reversing-ELF',
              description: 'Reverse Engineering CTF challenges',
              url: 'ctf.html?id=6',
              imgUrl: 'src/assets/CTF/Reversing-ELF/THM.jpg',
              category: 'TryHackMe',
              location: 'https://github.com/Berkanktk/CTFWriteups/tree/master/TryHackMe/Reversing-ELF'
            }, {
              id: 7,
              name: 'Baby-Encryption',
              description: 'You are after an organised crime group which is responsible for the illegal weapon market in your country. As a secret agent, you have infiltrated the group enough to be included in meetings with clients. During the last negotiation, you found one of the confidential messages for the customer. It contains crucial information about the delivery.',
              url: 'ctf.html?id=7',
              imgUrl: 'src/assets/CTF/Baby-Encryption/Encryption.jpg',
              category: 'HackTheBox',
              location: 'https://github.com/Berkanktk/CTFWriteups/tree/master/HackTheBox/Baby-Encryption'
            }],

        // list of portfolio items to loop through it
        allPortfolioItems:
          [
            {
              id: 1,
              url: 'single-portfolio.html?id=1',
              imgUrl: 'src/assets/projects/WOT/World_of_trash.jpg',
              link: 'https://github.com/esbendamkjaer/SE01-SE-T5G3-SI1-PRO',
              title: {
                en: 'World Of Trash',
              },
              date: {
                en: 'June 2020',
              },
              desc: {
                en: 'First Semester Project'
              },
              category: 'Java',
              tools: ['JavaFX'],
              screenshots: {
                img1: {
                  url: 'src/assets/projects/WOT/Collect.png',
                  caption: {
                    en: 'Collecting trash',
                  }
                },
                img2: {
                  url: 'src/assets/projects/WOT/Home.png',
                  caption: {
                    en: 'Player is at home',
                  }
                },
                img3: {
                  url: 'src/assets/projects/WOT/Talk.png',
                  caption: {
                    en: 'Interaction with other NPCs',
                  }
                }
              }
            },
            {
              id: 2,
              url: 'single-portfolio.html?id=2',
              imgUrl: 'src/assets/projects/TV2/tv2.png',
              link: 'https://github.com/amatzen/SE02-SE-G02-SPRO',
              title: {
                en: 'Credit Management System',
              },
              date: {
                en: 'February 2021',
              },
              desc: {
                en: 'Second Semester Project',
              },
              category: 'Java',
              tools: ['JavaFX', 'SceneBuilder'],
              screenshots: {
                img1: {
                  url: 'src/assets/projects/TV2/Mockup.jpg',
                  caption: {
                    en: 'Prototype',
                  }
                },
              }
            },
            {
              id: 3,
              url: 'single-portfolio.html?id=3',
              imgUrl: 'src/assets/projects/GTG/Chase.png',
              link: 'https://github.com/amatzen/SE04-SE-G09-SPRO',
              title: {
                en: 'Grand Theft Gørding',
              },
              date: {
                en: 'June 2022',
              },
              desc: {
                en: 'Fourth Semester Project',
              },
              category: 'Java',
              tools: ['LibGDX', 'OSGi', 'A*'],
              screenshots: {
                img1: {
                  url: 'src/assets/projects/GTG/Start.png',
                  caption: {
                    en: 'Start location',
                  }
                },
                img2: {
                  url: 'src/assets/projects/GTG/ATM.png',
                  caption: {
                    en: 'Robbed ATM',
                  }
                },
                img3: {
                  url: 'src/assets/projects/GTG/Close.png',
                  caption: {
                    en: 'Police getting close to the player',
                  }
                },
                img4: {
                  url: 'src/assets/projects/GTG/Wanted.png',
                  caption: {
                    en: 'The players wanted level increases',
                  }
                },
                img5: {
                  url: 'src/assets/projects/GTG/AI.png',
                  caption: {
                    en: 'The blue line shows the path following used by the police to search for the player',
                  }
                },
                img6: {
                  url: 'src/assets/projects/GTG/Wasted.png',
                  caption: {
                    en: 'Player dies',
                  }
                }
              },
            },
            {
              id: 4,
              url: 'single-portfolio.html?id=4',
              imgUrl: 'src/assets/projects/BeReal/BeReal_logo.png',
              link: 'https://github.com/Berkanktk/BeReal-Fetcher',
              title: {
                en: 'BeReal Fetcher',
              },
              date: {
                en: 'July 2022',
              },
              desc: {
                en: 'A script to scrape data from BeReal without posting anything.',
              },
              category: 'Python',
              tools: ['API', 'JSON', 'Requests'],
              screenshots:
              {
                img1: {
                  url: 'src/assets/projects/BeReal/BeReal-CLI.png',
                  caption: {
                    en: 'The CLI options prompt is made to give the user some options to get some specific data, from the fully fetched JSON file under /results.',
                  }
                },
                img2: {
                  url: 'src/assets/projects/BeReal/BeReal-JSON.png',
                  caption: {
                    en: 'Date formatted filenames containing the fully fetched data from BeReal.',
                  }
                }
              },
            },
            {
              id: 5,
              url: 'single-portfolio.html?id=5',
              imgUrl: 'src/assets/projects/StockChecker/Product-lookup.webp',
              link: 'https://github.com/Berkanktk/StockChecker',
              title: {
                en: 'Stock Checker',
              },
              date: {
                en: 'July 2022',
              },
              desc: {
                en: 'This is a simple selenium-based stock checker made with Python, that checks if a product is on stock on a specified platform.',
              },
              category: 'Python',
              tools: ['Selenium', 'Chromedriver', 'SMTP', 'Discord'],
              screenshots:
              {
                img1: {
                  url: 'src/assets/projects/StockChecker/Discord-announcement.png',
                  caption: {
                    en: 'Discord Announcement.',
                  }
                },
                img2: {
                  url: 'src/assets/projects/StockChecker/Email.png',
                  caption: {
                    en: 'Email notification.',
                  }
                }
              },
            },
            {
              id: 6,
              url: 'single-portfolio.html?id=6',
              imgUrl: 'src/assets/projects/UserGenerator/User-Generator.jpg',
              link: 'https://github.com/Berkanktk/UserGenerator',
              title: {
                en: 'User Generator',
              },
              date: {
                en: 'July 2022',
              },
              desc: {
                en: 'A simple fake user generator.',
              },
              category: 'JavaScript',
              tools: ['API', 'Vue.js', 'JSON'],
              screenshots:
              {
                img1: {
                  url: 'src/assets/projects/UserGenerator/Data.png',
                  caption: {
                    en: 'Cell highlighting.',
                  }
                },
                img2: {
                  url: 'src/assets/projects/UserGenerator/UI.png',
                  caption: {
                    en: 'UI Overview',
                  }
                }
              },
            },
            {
              id: 7,
              url: 'single-portfolio.html?id=7',
              imgUrl: 'src/assets/projects/YoutubeDownloader/Script.png',
              link: 'https://github.com/Berkanktk/YoutubeDownloader',
              title: {
                en: 'YouTube Downloader',
              },
              date: {
                en: 'May 2022',
              },
              desc: {
                en: 'A simple command line program for downloading and analyzing videos from YouTube.',
              },
              category: 'Python',
              tools: ['Pytube', 'Pyfiglet'],
              screenshots:
              {
                img1: {
                  url: 'src/assets/projects/YoutubeDownloader/Artwork.png',
                  caption: {
                    en: 'Pyfiglet Artwork.',
                  }
                },
                img2: {
                  url: 'src/assets/projects/YoutubeDownloader/Start.png',
                  caption: {
                    en: 'Running the script.',
                  }
                },
                img3: {
                  url: 'src/assets/projects/YoutubeDownloader/Options.png',
                  caption: {
                    en: 'Options for videos.',
                  }
                },
                img4: {
                  url: 'src/assets/projects/YoutubeDownloader/Script.png',
                  caption: {
                    en: 'Example usage.',
                  }
                },
              },
            },
            { 
              id: 12,
              url: 'single-portfolio.html?id=12',
              imgUrl: 'src/assets/projects/MarkDocX/Showcase.png',
              link: '#',
              title: {
                en: 'MarkDocX',
              },
              date: {
                en: 'September 2023',
              },
              desc: {
                en: 'This project is a simple notebook app that lets you write markdown, while autosaving the work in a SQLite3 database.',
              },
              category: 'JavaScript',
              tools: ['Svelte', 'Tailwind', 'DaisyUI', "SQLite3", "Node.js", "Express"],
              screenshots:
              {
                img1: {
                  url: 'src/assets/projects/MarkDocX/Landing.png',
                  caption: {
                    en: 'Landing page.',
                  }
                },
                img2: {
                  url: 'src/assets/projects/MarkDocX/Login.png',
                  caption: {
                    en: 'Login page.'
                  }
                },
                img3: {
                  url: 'src/assets/projects/MarkDocX/Showcase.png',
                  caption: {
                    en: 'Markdown and preview page.',
                  },
                },
                img4: {
                  url: 'src/assets/projects/MarkDocX/Autosave.png',
                  caption: {
                    en: 'Autosave feedback.',
                  },
                },
              },
            },
            {
              id: 14,
              url: 'single-portfolio.html?id=14',
              imgUrl: 'src/assets/projects/Algorithms/Sorting.png',
              link: 'https://berkanktk.github.io/Algorithm-visualizer/',
              title: {
                en: 'Algorithm Visualizer',
              },
              date: {
                en: 'October 2023',
              },
              desc: {
                en: 'Visualizing key computer science algorithms, including sorting, searching, mazegen, pathfinding, and data structures, in an interactive and user-friendly way.',
              },
              category: 'JavaScript',
              tools: ['Svelte', 'Tailwind', 'DaisyUI'],
              screenshots:
              {
                img1: {
                  url: 'src/assets/projects/Algorithms/Sorting2.png',
                  caption: {
                    en: 'Sorting overview',
                  }
                },
                img2: {
                  url: 'src/assets/projects/Algorithms/Search.png',
                  caption: {
                    en: 'Example of a search performed by a binary search algorithm.'
                  }
                },
                img3: {
                  url: 'src/assets/projects/Algorithms/Pathfinding.png',
                  caption: {
                    en: 'Maze generation using a maze generation algorithm.',
                  },
                },
                img4: {
                  url: 'src/assets/projects/Algorithms/Pathfinding2.png',
                  caption: {
                    en: 'Finding the shortest path using a pathfinding algorithm.',
                  },
                },
                img5: {
                  url: 'src/assets/projects/Algorithms/Data_structures.png',
                  caption: {
                    en: 'Data structures overview.',
                  },
                },
              },
            },
            { 
              id: 9,
              url: 'single-portfolio.html?id=9',
              imgUrl: 'src/assets/projects/TravMap/Dashboard.png',
              link: 'https://berkanktk.github.io/TravMap/',
              title: {
                en: 'TravMap',
              },
              date: {
                en: 'June 2023',
              },
              desc: {
                en: 'A custom map made with MapBox which provides many features such as flat/sphere world map, area comparison, directions and more.',
              },
              category: 'JavaScript',
              tools: ['JavaScript', 'MapBox'],
              screenshots:
              {
                img1: {
                  url: 'src/assets/projects/TravMap/demo1.png',
                  caption: {
                    en: 'Map overview with directions to a destination.',
                  }
                },
                img2: {
                  url: 'src/assets/projects/TravMap/demo2.png',
                  caption: {
                    en: 'Sattelite view.'
                  }
                },
                img3: {
                  url: 'src/assets/projects/TravMap/demo3.png',
                  caption: {
                    en: 'Monochrome view.',
                  },
                },
                img4: {
                  url: 'src/assets/projects/TravMap/compare1.png',
                  caption: {
                    en: 'Choosing area to compare',
                  },
                },
                img5: {
                  url: 'src/assets/projects/TravMap/compare2.png',
                  caption: {
                    en: 'Dragging over the drawn map from another city over the current one.',
                  },
                },
              },
            },
            { 
              id: 10,
              url: 'single-portfolio.html?id=10',
              imgUrl: 'src/assets/projects/Sharemefy/Home.png',
              link: 'https://sharemefy.web.app/',
              title: {
                en: 'Sharemefy',
              },
              date: {
                en: 'August 2023',
              },
              desc: {
                en: 'A social media link sharing platform.',
              },
              category: 'JavaScript',
              tools: ['Svelte', 'Firebase', 'Tailwind', 'DaisyUI'],
              screenshots:
              {
                img1: {
                  url: 'src/assets/projects/Sharemefy/Home.png',
                  caption: {
                    en: 'Landing page.',
                  }
                },
                img2: {
                  url: 'src/assets/projects/Sharemefy/Onboarding.png',
                  caption: {
                    en: 'Onboarding page consisting of 3 steps.'
                  }
                },
                img3: {
                  url: 'src/assets/projects/Sharemefy/Edit.png',
                  caption: {
                    en: 'Edit profile page.',
                  },
                },
                img4: {
                  url: 'src/assets/projects/Sharemefy/Profile.png',
                  caption: {
                    en: 'User profile page.',
                  },
                },
                img5: {
                  url: 'src/assets/projects/Sharemefy/Error.png',
                  caption: {
                    en: 'Error page which can show different errors including private profiles.',
                  },
                },
              },
            },
            {
              id: 8,
              url: 'single-portfolio.html?id=8',
              imgUrl: 'src/assets/projects/ScratchMap/Map.png',
              link: 'https://myscratchmap.net/',
              title: {
                en: 'Scratch Map',
              },
              date: {
                en: 'February 2023',
              },
              desc: {
                en: 'A simple online scratch map.',
              },
              category: 'JavaScript',
              tools: ['Angular', 'JsVectorMap'],
              screenshots:
              {
                img1: {
                  url: 'src/assets/projects/ScratchMap/Map03.png',
                  caption: {
                    en: 'Map overview',
                  }
                },
                img2: {
                  url: 'src/assets/projects/ScratchMap/Map02.png',
                  caption: {
                    en: 'Search for a country.'
                  }
                },
                img3: {
                  url: 'src/assets/projects/ScratchMap/Map01.png',
                  caption: {
                    en: 'Full overview of the website.',
                  },
                },
              },
            },
            { 
              id: 13,
              url: 'single-portfolio.html?id=13',
              imgUrl: 'src/assets/projects/Weatherly/Rain_crop.gif',
              link: 'https://berkanktk.github.io/Weatherly/',
              title: {
                en: 'Weatherly',
              },
              date: {
                en: 'September 2023',
              },
              desc: {
                en: 'A minimalistic weather app made with Svelte and Tailwind/DaisyUI.',
              },
              category: 'JavaScript',
              tools: ['Svelte', 'Tailwind', 'DaisyUI'],
              screenshots:
              {
                img1: {
                  url: 'src/assets/projects/Weatherly/01.png',
                  caption: {
                    en: 'Home page.',
                  }
                },
                img2: {
                  url: 'src/assets/projects/Weatherly/02.png',
                  caption: {
                    en: 'Search page.'
                  }
                },
                img3: {
                  url: 'src/assets/projects/Weatherly/03.png',
                  caption: {
                    en: 'Weather forecast (5 days) - Without raining atm.',
                  },
                },
                img4: {
                  url: 'src/assets/projects/Weatherly/04.png',
                  caption: {
                    en: 'Weather forecast (5 days) - With raining atm.',
                  },
                },
                img5: {
                  url: 'src/assets/projects/Weatherly/Demo.gif',
                  caption: {
                    en: 'Quick demo.',
                  },
                },
              },
            },
            { 
              id: 11,
              url: 'single-portfolio.html?id=11',
              imgUrl: 'src/assets/projects/SimpleCountdown/Demo_short_crop.gif',
              link: 'https://berkanktk.github.io/SimpleCountdown/',
              title: {
                en: 'SimpleCountdown',
              },
              date: {
                en: 'September 2023',
              },
              desc: {
                en: 'A simple and minimalistic countdown made with Svelte and Tailwind/DaisyUI.',
              },
              category: 'JavaScript',
              tools: ['Svelte', 'Tailwind', 'DaisyUI'],
              screenshots:
              {
                img1: {
                  url: 'src/assets/projects/SimpleCountdown/Landing.png',
                  caption: {
                    en: 'Landing page.',
                  }
                },
                img2: {
                  url: 'src/assets/projects/SimpleCountdown/Waiting.png',
                  caption: {
                    en: 'Waiting for the countdown to finish.'
                  }
                },
                img3: {
                  url: 'src/assets/projects/SimpleCountdown/Congrats.png',
                  caption: {
                    en: 'Page after the countdown has finished.',
                  },
                },
                img4: {
                  url: 'src/assets/projects/SimpleCountdown/Demo.gif',
                  caption: {
                    en: 'Quick demo.',
                  },
                },
              },
            },
            { 
              id: 12,
              url: 'single-portfolio.html?id=12',
              imgUrl: 'src/assets/projects/character-analyzer/demo-dark.png',
              link: 'https://berkanktk.github.io/CharacterAnalyzer/',
              title: {
                en: 'CharacterAnalyzer',
              },
              date: {
                en: 'October 2023',
              },
              desc: {
                en: 'A simple yet powerful character analyzer built with SvelteKit, Tailwind, and DaisyUI.',
              },
              category: 'JavaScript',
              tools: ['Svelte', 'Tailwind', 'DaisyUI'],
              screenshots:
              {
                img1: {
                  url: 'src/assets/projects/character-analyzer/demo-dark.png',
                  caption: {
                    en: 'A simple demo of the website.',
                  }
                },
                img2: {
                  url: 'src/assets/projects/character-analyzer/capitalized.png',
                  caption: {
                    en: 'Capitalize Text in Various Ways: Apply capitalization rules to make your text conform to different styles.',
                  },
                },
                img3: {
                  url: 'src/assets/projects/character-analyzer/comparison.png',
                  caption: {
                    en: 'Compare two pieces of text to identify differences and similarities. This feature is ideal for writers and editors who need to compare different versions of the same text. The tool also shows the total amount of differences in the two texts.',
                  },
                },
                img4: {
                  url: 'src/assets/projects/character-analyzer/random.png',
                  caption: {
                    en: 'Create Random Capitalization: Add a touch of randomness to your text, creating a playful or eye-catching appearance.',
                  },
                },
                img5: {
                  url: 'src/assets/projects/character-analyzer/search-replace.png',
                  caption: {
                    en: 'Efficiently search for specific terms or phrases within your text and replace them with ease using the built-in search and replace tool. Perfect for making quick corrections or text modifications.',
                  },
                },
                img6: {
                  url: 'src/assets/projects/character-analyzer/uppercase.png',
                  caption: {
                    en: 'Convert Text to Uppercase or Lowercase: Change the letter case of your text with a single click.',
                  },
                },
              },
            },


          ].reverse(),
        // viewed portfolio items
        portfolioItems: [],
        ctfItems: [],
        guideItems: [],

        allGuides: [{
          id: '1',
          name: 'JDBC Guide (IntelliJ)',
          description: 'A small guide for adding a PostgreSQL database to your IntelliJ project.',
          filename: 'JDBC Guide (IntelliJ).pdf',
          download: 'src/assets/documents/JDBC-Guide-(IntelliJ).pdf'
        }, {
          id: '2',
          name: 'Single Unity Executable',
          description: 'A quick guide for how you can add a single executable to your Unity project using WinRAR.',
          filename: 'Standalone_Executable_with_unity.pdf',
          download: 'src/assets/documents/Standalone_Executable_with_Unity.pdf'
        },
          //   {
          //   id: '2',
          //   name: 'XML Guide (IntelliJ)',
          //   description: 'A small XML guide.',
          //   filename: 'JDBC Guide (IntelliJ).pdf',
          //   download: 'src/assets/documents/JDBC-Guide-(IntelliJ).pdf'
          // }, {
          //   id: '3',
          //   name: 'SQL Guide',
          //   description: 'A small SQL guide.',
          //   filename: 'JDBC Guide (IntelliJ).pdf',
          //   download: 'src/assets/documents/JDBC-Guide-(IntelliJ).pdf'
          // }
        ],

      };
    },

    created() {
      // get a theme to use
      this.getAppTheme();
    },

    mounted() {
      if (window.innerWidth >= 992) {
        // initialize circle cursor
        this.initCircleCursor(); // apply pan effect hero image

        this.heroImgPanEffect(); // initialize VanillaTilt library in portfolio section

        this.initializeTilt();

        this.initializeTiltCTF()

        this.initializeTiltGuide()

      } // nav menu tab trap


      this.navMenuTabTrap(); // scrolling options

      this.scrollingOptions();
      document.addEventListener('scroll', () => this.scrollingOptions()); // initialize popper.js plugin

      document.querySelectorAll('.has-ultimate-tooltip').forEach(el => {
        Popper.createPopper(el, el.querySelector('.ultimate-tooltip'), {
          placement: 'top',
          modifiers: [{
            name: 'offset',
            options: {
              offset: [0, 30]
            }
          }]
        });
      }); // get portfolio items

      this.getPortfolioItems(); // init glightbox plugin

      this.getCTFItems()

      new GLightbox({
        autoplayVideos: false
      }); // initialize the first displayed type of skills

      this.initSkillsFirstType();
    },

    methods: {
      // initialize circle cursor
      initCircleCursor() {
        const app = this.$refs.appRef;
        const outer = this.$refs.circleCursorOuter;
        const inner = this.$refs.circleCursorInner; // return if disabled

        if (!outer || !inner) {
          return;
        }

        app.addEventListener('mousemove', e => {
          // make the circles follow the cursor
          outer.setAttribute('style', `visibility: visible; top: ${e.clientY}px; left: ${e.clientX}px;`);
          inner.setAttribute('style', `visibility: visible; top: ${e.clientY}px; left: ${e.clientX}px;`); // add link hover style

          e.target.closest('a') || e.target.closest('button') || e.target.closest('.link-hover') ? inner.classList.add('cursor-link-hover') : inner.classList.remove('cursor-link-hover');
        });
        app.addEventListener('click', () => {
          // add pulse effect on click
          inner.classList.add('cursor-click-effect');
          setTimeout(() => inner.classList.remove('cursor-click-effect'), 200);
        });
      },

      // get a theme to use
      getAppTheme() {
        // get the saved theme from the localStorage
        const storageSavedTheme = localStorage.getItem('berkanSavedTheme'); // Check to see if there a saved theme

        if (storageSavedTheme) {
          this.savedTheme = storageSavedTheme;
        } else {
          // So, try to get the browser default theme or make your own default
          // Check to see if Media-Queries are supported
          if (window.matchMedia) {
            // Check if the dark-mode Media-Query matches
            if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
              this.savedTheme = 'dark_theme';
            } else {
              this.savedTheme = 'light_theme';
            }
          } else {
            // Default (when Media-Queries are not supported)
            this.savedTheme = this.appTheme;
          }
        } // save the new theme in the localStorage


        localStorage.setItem('berkanSavedTheme', this.savedTheme);
      },

      // detect the theme changes
      changeAppTheme() {
        this.savedTheme === 'light_theme' ? this.savedTheme = 'dark_theme' : this.savedTheme = 'light_theme'; // save the new theme in the localStorage

        localStorage.setItem('berkanSavedTheme', this.savedTheme);
      },

      // toggle nav menu
      toggleNavMenu() {
        this.isNavMenuOpen = !this.isNavMenuOpen;
        this.isNavMenuOpen ? this.openNavMenu() : this.closeNavMenu();
      },

      // open nav menu
      openNavMenu() {
        const bodyEl = document.getElementsByTagName('body')[0];
        this.isNavMenuOpen = true;
        bodyEl.setAttribute('style', 'overflow-y: hidden;'); // set focus on nav menu

        this.$refs.headerNav.querySelector('.desktop-menu-content').focus();
      },

      // close nav menu
      closeNavMenu() {
        const bodyEl = document.getElementsByTagName('body')[0];
        this.isNavMenuOpen = false;
        bodyEl.removeAttribute('style'); // set focus on nav menu toggle button

        this.$refs.navMenuToggleBtn.focus();
      },

      // nav menu tab trap
      navMenuTabTrap() {
        const nav = this.$refs.headerNav;
        const focusableElementsString = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]';
        let firstTabStop;
        let lastTabStop;
        let isFirstTabStop;
        let isLastTabStop;
        document.addEventListener('keyup', e => {
          if (nav.classList.contains('menu-open')) {
            // get first & last focusable elements in the side menu for the tab trap
            const visibleFocusableEls = [...nav.querySelectorAll(focusableElementsString)].filter(el => window.getComputedStyle(el).getPropertyValue('visibility') !== 'hidden');
            firstTabStop = visibleFocusableEls[0];
            lastTabStop = visibleFocusableEls[visibleFocusableEls.length - 1];

            if (e.code === 'Tab') {
              if (e.shiftKey)
              /* shift + tab */ {
                // if this is the first item, move to the last item
                isFirstTabStop && lastTabStop.focus();
              } else
              /* tab */ {
                // if this is the last item, go back to the first item
                isLastTabStop && firstTabStop.focus();
              } // close nav menu on Escape button press

            } else if (e.code === 'Escape') {
              this.toggleNavMenu();
            } // get current active element


            const activeEl = document.activeElement; // check if last item or not

            isLastTabStop = activeEl === lastTabStop ? true : false; // check if first item or not

            isFirstTabStop = activeEl === firstTabStop ? true : false;
          }
        });
      },

      // apply pan effect hero image
      heroImgPanEffect() {
        const parent = this.$refs.heroSection; // return if disabled

        if (!parent || !parent.getAttribute('data-paneffect')) {
          return;
        }

        const layer1 = parent.querySelectorAll('.layer')[0];
        const layer2 = parent.querySelectorAll('.layer')[1];
        parent.addEventListener('mousemove', e => {
          const x = (e.x - parent.getBoundingClientRect().x) / parent.offsetWidth * 100;
          const y = (e.y - parent.getBoundingClientRect().y) / parent.offsetHeight * 100;
          parent.classList.add('parallax-animation');
          layer1.setAttribute('style', `transform-origin: ${x}vw ${y}vh;`);
          layer2.setAttribute('style', `transform-origin: ${x}vw ${y}vh;`);
        });
      },

      // scrolling options
      scrollingOptions() {
        const scrollPosition = window.pageYOffset; // check for current scroll position to minimize the header

        this.isHeaderBig = scrollPosition >= this.startMinimizingHeaderAt ? false : true; // check for current scroll position to toggle the header

        this.isHeaderHidden = scrollPosition > 100 && scrollPosition > this.lastScrollPosition ? true : false;
        this.lastScrollPosition = scrollPosition;
      },

      // scroll to top
      scrollToTop() {
        window.scroll({
          top: 0,
          behavior: 'smooth'
        });
      },

      // initialize the first displayed type of skills
      initSkillsFirstType() {
        const skillsSwitchBtn = this.$refs.skillsSwitchBtn; // return if disabled

        if (!skillsSwitchBtn) {
          return;
        }

        this.skillsType = skillsSwitchBtn.querySelector('input').value;
      },

      // initialize VanillaTilt library in portfolio section
      initializeTilt() {
        const portfolioItems = this.$refs.portfolioItems; // return if disabled

        if (!portfolioItems) {
          return;
        }

        VanillaTilt.init(portfolioItems.querySelectorAll('.portfolio-item'), {
          max: 8,
          speed: 400,
          glare: true,
          'max-glare': 0.3
        });
      },

      // initialize VanillaTilt library in portfolio section
      initializeTiltCTF() {
        const ctfItems = this.$refs.ctfItems; // return if disabled
        if (!ctfItems) {
          return;
        }

        VanillaTilt.init(ctfItems.querySelectorAll('.portfolio-item'), {
          max: 8,
          speed: 400,
          glare: true,
          'max-glare': 0.3
        });
      },

      initializeTiltGuide() {
        const guideItems = this.$refs.guideItems; // return if disabled
        if (!guideItems) {
          return;
        }

        VanillaTilt.init(guideItems.querySelectorAll('.portfolio-item'), {
          max: 8,
          speed: 400,
          glare: true,
          'max-glare': 0.3
        });
      },

      // get portfolio items
      getPortfolioItems() {
        const itemsArr = this.allPortfolioItems.filter(item => {
          const urlParams = new URLSearchParams(window.location.search);
          const tax = urlParams.get('tax');

          if (tax) {
            if (tax === 'cat') {
              const cat = urlParams.get('cat');
              this.portfolioArchiveName = cat;
              return item.category === cat;
            } else if (tax === 'tools') {
              const tool = urlParams.get('tools');
              this.portfolioArchiveName = tool;
              return item.tools.includes(tool);
            }
          } else {
            return this.currentFilter === 'All' || item.category === this.currentFilter;
          }
        }).slice(this.filteredPortfolioItems.length, this.portfolioItemsPage * this.itemsPerPage); // check if have works or not

        if (itemsArr.length) {
          this.portfolioItems.push(...itemsArr);
          this.$nextTick(() => {
            // reinitialize VanillaTilt for new items
            this.portfolioItemsPage > 1 && this.initializeTilt(); // Forces the ScrollTrigger instance to re-calculate its start and end values

            setTimeout(() => ScrollTrigger.refresh(), 500);
          });
          this.portfolioItemsPage++;
        } else {
          // show message "No works" to the user
          this.setNotify({
            className: 'danger',
            msg: this.$refs.portfolioItems.getAttribute('data-no-works-msg'),
            time: 3000
          });
        }
      },

      // get ctf items
      getCTFItems() {
        const itemsArr = this.allCTFPages.filter(item => {
          const urlParams = new URLSearchParams(window.location.search);
          return this.currentCTFilter === 'All' || item.category === this.currentCTFilter;

        }).slice(this.filteredCTFItems.length, this.ctfItemsPage * this.itemsPerPage); // check if have works or not

        if (itemsArr.length > 0) {
          this.ctfItems.push(...itemsArr);
          this.$nextTick(() => {
            // reinitialize VanillaTilt for new items
            this.ctfItemsPage > 1 && this.initializeTiltCTF(); // Forces the ScrollTrigger instance to re-calculate its start and end values

            setTimeout(() => ScrollTrigger.refresh(), 500);
          });
          this.ctfItemsPage++;
        } else {
          // show message "No works" to the user
          this.setNotify({
            className: 'danger',
            msg: this.$refs.ctfItems.getAttribute('data-no-works-msg'),
            time: 3000
          });
        }
      },

      // filter portfolio items
      filterPortfolioItems(filter) {
        this.currentFilter = filter;
        this.portfolioItemsPage = 1;

        if (this.filteredPortfolioItems.length) {
          this.$nextTick(() => {
            // reinitialize VanillaTilt for new items
            this.portfolioItemsPage > 1 && this.initializeTilt(); // Forces the ScrollTrigger instance to re-calculate its start and end values

            setTimeout(() => ScrollTrigger.refresh(), 500);
          });
        } else {
          // get new portfolio items
          this.getPortfolioItems();
        }
      },

      filterCTFItems(filter) {
        this.currentCTFilter = filter;
        this.ctfItemsPage = 1;

        if (this.filteredCTFItems.length) {
          this.$nextTick(() => {
            // reinitialize VanillaTilt for new items
            this.ctfItemsPage > 1 && this.initializeTiltCTF(); // Forces the ScrollTrigger instance to re-calculate its start and end values

            setTimeout(() => ScrollTrigger.refresh(), 500);
          });
        } else {
          // get new ctf items
          this.getCTFItems();
        }
      },

      // contact form validation
      contactFormValidation() {
        // contact form
        const contactForm = this.$refs.contactForm; // form controls

        const name = contactForm.querySelector('input[name="name"]');
        const email = contactForm.querySelector('input[name="email"]');
        const phone = contactForm.querySelector('input[name="phone"]');
        const message = contactForm.querySelector('textarea'); // form validation status

        let errors = {
          name: {
            required: true,
            minLength: true
          },
          email: {
            required: true,
            invalid: true
          },
          phone: {
            invalid: true
          },
          message: {
            required: true
          }
        };
        /* --------------- */

        /* name validation */

        /* --------------- */
        // required validation

        if (name.value === '') {
          errors.name.required = true;
          this.setNotify({
            id: 'nameRequired',
            className: 'danger',
            msg: name.closest('.control').querySelector('.errors-msgs .required').value
          });
        } else {
          errors.name.required = false;
          this.dismissNotify('nameRequired');
        } // minlength validation


        if (name.value.length > 0 && name.value.length < name.getAttribute('minlength')) {
          errors.name.minLength = true;
          this.setNotify({
            id: 'nameMinLength',
            className: 'danger',
            msg: name.closest('.control').querySelector('.errors-msgs .minLength').value
          });
        } else {
          errors.name.minLength = false;
          this.dismissNotify('nameMinLength');
        } // toggle invalid errors & style classes


        if (Object.keys(errors.name).some(err => errors.name[err] === true)) {
          name.classList.remove('valid');
          name.classList.add('invalid');
        } else {
          name.classList.remove('invalid');
          name.classList.add('valid');
        }
        /* ---------------- */

        /* email validation */

        /* ---------------- */
        // required validation


        if (email.value === '') {
          errors.email.required = true;
          this.setNotify({
            id: 'emailRequired',
            className: 'danger',
            msg: email.closest('.control').querySelector('.errors-msgs .required').value
          });
        } else {
          errors.email.required = false;
          this.dismissNotify('emailRequired');
        } // email validation


        if (email.value.length > 0 && !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email.value)) {
          errors.email.invalid = true;
          this.setNotify({
            id: 'emailInvalid',
            className: 'danger',
            msg: email.closest('.control').querySelector('.errors-msgs .invalid').value
          });
        } else {
          errors.email.invalid = false;
          this.dismissNotify('emailInvalid');
        } // toggle invalid errors & style classes


        if (Object.keys(errors.email).some(err => errors.email[err] === true)) {
          email.classList.remove('valid');
          email.classList.add('invalid');
        } else {
          email.classList.remove('invalid');
          email.classList.add('valid');
        }
        /* ---------------- */

        /* phone validation */

        /* ---------------- */
        // phone validation


        if (phone.value.length > 0 && !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(phone.value)) {
          errors.phone.invalid = true;
          this.setNotify({
            id: 'phoneInvalid',
            className: 'danger',
            msg: phone.closest('.control').querySelector('.errors-msgs .invalid').value
          });
        } else {
          errors.phone.invalid = false;
          this.dismissNotify('phoneInvalid');
        } // toggle invalid errors & style classes


        if (Object.keys(errors.phone).some(err => errors.phone[err] === true)) {
          phone.classList.remove('valid');
          phone.classList.add('invalid');
        } else {
          phone.classList.remove('invalid');
          phone.classList.add('valid');
        }
        /* ------------------ */

        /* message validation */

        /* ------------------ */
        // required validation


        if (message.value === '') {
          errors.message.required = true;
          this.setNotify({
            id: 'messageRequired',
            className: 'danger',
            msg: message.closest('.control').querySelector('.errors-msgs .required').value
          });
        } else {
          errors.message.required = false;
          this.dismissNotify('messageRequired');
        } // toggle invalid errors & style classes


        if (Object.keys(errors.message).some(err => errors.message[err] === true)) {
          message.classList.remove('valid');
          message.classList.add('invalid');
        } else {
          message.classList.remove('invalid');
          message.classList.add('valid');
        } // send the message if the form is valid


        !Object.values(errors).some(control => Object.values(control).some(Boolean)) && this.sendContactFormMessage(contactForm);
      },

      // send message from contact form
      sendContactFormMessage(form) {
        const url = form.getAttribute('action');
        const formData = new FormData(form); // start loading spinner

        this.startLoading(); // send post request

        fetch(url, {
          method: 'POST',
          body: formData
        }).then(res => res.text()).then(data => {
          if (data === 'success') {
            // show success message
            this.setNotify({
              className: 'success',
              msg: form.getAttribute('data-success-msg'),
              time: 5000
            }); // reset all form inputs

            form.reset(); // remove inputs valid classes

            form.querySelectorAll('.valid').forEach(el => el.classList.remove('valid'));
          } else if (data === 'error') {
            // show error message
            this.setNotify({
              className: 'danger',
              msg: form.getAttribute('data-err-msg'),
              time: 5000
            });
          } // end loading spinner


          this.endLoading();
          console.log(data);
        }).catch(err => console.log(err));
      },

      // show messages by toast notifications
      setNotify({
        id,
        className,
        msg,
        time
      }) {
        const notify = {
          id: id || `${Date.now()}${this.notifications.length}`,
          className,
          msg,
          time
        };

        if (id) {
          !this.notifications.some(e => e.id === id) && this.notifications.push(notify);
        } else {
          this.notifications.push(notify);
        } // remove this notification from the array after (n) seconds


        time && setTimeout(() => this.dismissNotify(notify.id), time);
      },

      // dismiss the notifications
      dismissNotify(id) {
        const index = this.notifications.findIndex(notify => notify.id === id);
        index > -1 && this.notifications.splice(index, 1);
      },

      // add ajax loading spinner
      startLoading() {
        this.ajaxLoading.push(true);
      },

      // remove ajax loading spinner
      endLoading() {
        this.ajaxLoading.pop();
      }

    },
    computed: {
      // flag to toggle ajax loading spinner
      isAjaxLoading() {
        return this.ajaxLoading.some(state => state === true);
      },

      // get the total years of experience
      experienceYears() {
        return new Date(new Date() - new Date(String(this.careerStartDate))).getFullYear() - 1970;
      },

      getAge() {
        const ageInMilliseconds = new Date() - new Date('2000-12-16');
        return Math.floor(ageInMilliseconds / 1000 / 60 / 60 / 24 / 365);
      },

      getTotalProjects() {
        return this.allPortfolioItems.length;
      },

      getTotalGuides() {
        return this.allGuides.length;
      },

      getTotalCTFWriteups() {
        return this.allCTFPages.length;
      },

      // split experience items into chunks of 3 items
      experienceChunks() {
        return [...Array(Math.floor((this.experienceItems.length - 1) / 3))];
      },

      // filtered portfolio items
      filteredPortfolioItems() {
        const urlParams = new URLSearchParams(window.location.search);
        const tax = urlParams.get('tax');

        if (tax) {
          return this.portfolioItems;
        } else {
          return this.portfolioItems.filter(item => this.currentFilter === 'All' || item.category === this.currentFilter);
        }
      },

      filteredCTFItems() {
        const urlParams = new URLSearchParams(window.location.search);
        const tax = urlParams.get('tax');

        if (tax) {
          return this.ctfItems;
        } else {
          return this.ctfItems.filter(item => this.currentCTFilter === 'All' || item.category === this.currentCTFilter);
        }
      },

      // get single portfolio item
      getSinglePortfolioItem() {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');
        return this.allPortfolioItems.find(item => item.id == id);
      },

      getSingleCTFItem() {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');
        return this.allCTFPages.find(item => item.id == id);
      },

      getSingleGuideItem() {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');
        return this.allGuides.find(item => item.id == id);
      },

      // get the total years of copyright
      copyrightDate() {
        const yearsDuration = new Date(new Date() - new Date(String(this.copyrightStartDate))).getFullYear() - 1970;
        return yearsDuration === 0 ? this.copyrightStartDate : `${this.copyrightStartDate} - ${this.copyrightStartDate + yearsDuration}`;
      }

    },
    directives: {
      // add stagger delay to children elements
      staggerdelay: {
        mounted(el, binding) {
          [...el.children].forEach((child, i) => {
            child.setAttribute('style', `animation-delay: ${(i + 1) * (binding.value || 100)}ms`);
          });
        }

      },
      // tooltip directive
      tooltip: {
        mounted(el, binding) {
          el.classList.add('has-tooltip');
          el.insertAdjacentHTML('beforeend', `<div class="custom-tooltip custom-tooltip-${binding.value.dir}">${binding.value.text}</div>`);
        }

      }
    }
  });
  app.mount('#app');
})();
